@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

body{
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.flexBox{
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
}
.flexBox > div{
  height: 100vh;
  flex: 1;
  min-width: 350px;
}

.left > footer{
  position: absolute;
  bottom:0;
  width: 50%;
  padding:25px;
  color: #3a3a3a;
  font-size: small;
  z-index:1;
}

h1{
  margin-top:100px;
  color: #e58929;
}
h1 img{
  position: relative;
  top:10px;
}
h2{
  color: #4dadf1;
}
.emailForm{
  background: #f9f7f7;
  padding: 20px;
  text-align: center;
  max-width: 500px;
  margin: auto;
  margin-top: 70px;
  margin-bottom: 50px;
}

.example{
  background:#4dadf1;
  height: 100vh;
  position: relative;
}

.emailForm input[type=submit],input[type=email]{
  height: 50px;
  font-family: 'Roboto', sans-serif;
  display: block;
  background: none;
  /* flex: 1; */
  outline: none;
  margin: 0 auto 10px auto;
  border-radius: 4px;
  min-height: 22px;
  padding: 5px;
  border: 1px solid #ccc;
  width:350px;
  box-sizing: border-box;
}

.emailForm input[type=submit]{
  background: #f5c14d;
  color:white;
}

.emailForm input[type=submit]:hover{
  background: #f3daa4;
  cursor: pointer;
}

.exampleEmail{
  background:white;
  padding:50px;
  min-width:350px;
  margin:auto;
  text-align: left;
  height:500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.exampleEmail p{
  line-height: 44px;
}

.exampleEmail .emailHeader{
  font-size: 18px;
}